document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";
    $('.js__masonry-menu').masonry({
        // options
        itemSelector: '.js__masonry-menu-item',
        percentPosition: true,
        gutter: 33
    });

    $('.js__catalog-page').masonry({
        // options
        itemSelector: '.js__catalog-page-item',
        percentPosition: true,
        gutter: 33
    });


    let homeSlider = new Swiper('.home-slider', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    let newSlider = new Swiper(".js__new-slider", {
        loop: true,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next--new',
            prevEl: '.swiper-button-prev--new',
        },
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            }
        }
    });
    let recommendSlider = new Swiper(".js__catalog-recommend", {
        loop: true,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next--recommend',
            prevEl: '.swiper-button-prev--recommend',
        },
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            }
        }
    });

    let galleryThumbs = new Swiper('.product-slider-main__nav', {
        direction: 'vertical',
        spaceBetween: 15,
        slidesPerView: 'auto',
        loop: false,

    });
    let galleryTop = new Swiper('.product-slider-main__big', {
        loop: false,
        watchOverflow : true,
        spaceBetween: 15,
        // autoplay: {
        //     delay: 4000,
        //     disableOnInteraction: false,
        // },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            clickable: true,
        },
        thumbs: {
            swiper: galleryThumbs,
        },
    });




    /// select

    let SlimSelectSize = document.querySelector(".js__SlimSelectSize");
    let SlimSelectCount = document.querySelector(".js__SlimSelectCount");

    if (SlimSelectSize) {
        new SlimSelect({
            select: SlimSelectSize,
            showSearch: false,
            placeholder: 'Выберите размер',
            showContent: 'down'
        });
    }

    if (SlimSelectCount) {
        new SlimSelect({
            select: SlimSelectCount,
            showSearch: false,
            placeholder: 'Количество комплектов',
            showContent: 'down'
        });
    }


});
